
@import url('https://fonts.googleapis.com/css2?family=Avenir:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap'
);



/* @tailwind base;
@tailwind components;
@tailwind utilities; */

*>* {
  font-family: 'Avenir', sans-serif;
}

body {
  background: #F2F2F2;

}

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}