.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.logo{
    margin: 10px;
    background-color: transparent;
    max-width: 100%; 
    height: auto; 
    display: block; 
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    width: 40vw;
    background-color: #FAFAFA;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
      
}
.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center
}
.p{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center
}
.form{
    width: 50%;
   }
  .btn {
    width: 100%; 
    padding: 10px;
    color: #ffffff;
    border-radius: 10px;
    border: none;
    background-color: #FD9C42;
    cursor: pointer;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; 
    gap: 1rem;
  }
  
  .footerLink {
    text-align: center;
  }
  
  .footerLink a {
    text-decoration: none;
    color: #333; 
  }
  
  .footerLink a:hover {
    text-decoration: underline;
  }
.boldText{
  font: bold;
  font-size: 20px;
}
  