.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.logo{
    margin: 10px;
    background-color: transparent;
    max-width: 100%; 
    height: auto; 
    display: block; 
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    width: 40vw;
    background-color: #FAFAFA;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
      
}
.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center
}
.p{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center
}
