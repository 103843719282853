.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.logo{
    margin: 10px;
    background-color: transparent;
    max-width: 100%; 
    height: auto; 
    display: block; 
}

.btndiv{
    width: 20%;
   }
  .btn {
    width: 100%; 
    padding: 10px;
    color: #ffffff;
    border-radius: 10px;
    border: none;
    background-color: #FD9C42;
    cursor: pointer;
  }
  /* modal */

  body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     top: 0;
    left: 0;
    right: 0;
     bottom: 0; 
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);

}
.modalContent {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
   
}
.modalContent h2{
    margin-top: 30px;
  }

.closeModal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    cursor: pointer;
}

.delete{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.deletebtn {
    width: 50%; 
   
    padding: 10px;
    color: #ffffff;
    border-radius: 10px;
    border: none;
    background-color: #FD9C42;
    cursor: pointer;
  }