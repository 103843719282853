.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.logo{
    margin: 10px;
    background-color: transparent;
    max-width: 100%; 
    height: auto; 
    display: block; 
}

.btndiv{
    width: 20%;
   }
  .btn {
    width: 100%; 
    padding: 10px;
    color: #ffffff;
    border-radius: 10px;
    border: none;
    background-color: #FD9C42;
    cursor: pointer;
  }