.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.logo{
    margin: 10px;
    background-color: transparent;
    max-width: 100%; 
    height: auto; 
    display: block; 
}

.header{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.p{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: lighter;
    
}


a{
  text-decoration: none;
}
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 50%;
    width: 50%;
    
  }
  .inputdiv{

    width: 50%; 
    height: 20%;
  }
   .input{
    padding: 15px;
    width: 100%;
    border-radius: 5px;
    border: none;
   }

   .btndiv{
    width: 50%;
   }
  .btn {
    width: 100%; 
    padding: 10px;
    color: #ffffff;
    border-radius: 10px;
    border: none;
    background-color: #FD9C42;
    cursor: pointer;
  }
